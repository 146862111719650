import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

const NoDataScreen = ({ classNameImg }) => {
    const { t } = useTranslation()
    return (
        <div className=" h-[400px] relative">
            <div className="image-wrapper w-full h-full bg-gray-100">
                <img
                    src='/img/others/nodata-prospect2.svg'
                    className={classNames('m-auto', 'w-[300px] h-full')}
                    alt={'No Data'}
                />
            </div>
            <div className="absolute bottom-10 left-1/2 -translate-y-1/2 -translate-x-1/2">
                <h3 className="text-gray-500">{t("global.txtNoData")}</h3>
            </div>
        </div>
    )
}

export default NoDataScreen