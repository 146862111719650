import ApiService from './ApiService'
import API from 'configs/apis'
import {constructUrlWithParams} from 'utils/utils'

export async function apiGetLocations(data) {
    const result = await ApiService.fetchData({
        url: API.getLocations,
        method: 'get',
        params: data
    })
    return result
}


export async function apiGetLocationForm(locationId) {
    return ApiService.fetchData({
        url: constructUrlWithParams(API.getLocationDetails, {
            location_id: locationId
        }),
        method: 'get',
    })
}


export async function apiAddLocation(data) {
    return ApiService.fetchData({
        url: API.getLocations,
        method: 'post',
        data
    })
}

export async function apiEditLocation(data) {
    return ApiService.fetchData({
        url: constructUrlWithParams(API.getLocationDetails, {
            location_id: data.id
        }),
        method: 'patch',
        data
    })
}

export async function apiDeleteLocation(locationId) {
    return ApiService.fetchData({
        url: constructUrlWithParams(API.getLocationDetails, {
            location_id: locationId
        }),
        method: 'delete',
    })
}

export async function apiLocationStartNew(locationId) {
    return ApiService.fetchData({
        url: constructUrlWithParams(API.locationStartNew, {
            location_id: locationId
        }),
        method: 'post',
    })
}

export async function apiLocationNewBatch(locationId) {
    return ApiService.fetchData({
        url: constructUrlWithParams(API.locationNewBatch, {
            location_id: locationId
        }),
        method: 'post',
    })
}
export async function apiLocationMoveBatch(locationId, data) {
    return ApiService.fetchData({
        url: constructUrlWithParams(API.locationMoveBatch, {
            location_id: locationId
        }),
        method: 'post',
        data
    })
}