import { createSlice } from '@reduxjs/toolkit'
import { TIMEZONE } from 'constants/api.constant'

export const initialState = {
    timezone: TIMEZONE
}

export const tenantSettingSlice = createSlice({
    name: 'base/tenantSetting',
    initialState,
    reducers: {
        setTimezone: (state, action) => {
            state.timezone = action.payload
        },
    },
})

export const { setTimezone } = tenantSettingSlice.actions

export default tenantSettingSlice.reducer
