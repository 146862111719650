import { PERSIST_CURRENT_LOCATION, PERSIST_STORE_NAME } from 'constants/app.constant';
import ApiService from './ApiService'
import API from 'configs/apis';

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: API.signIn,
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut() {
    localStorage.removeItem(PERSIST_STORE_NAME)
    localStorage.removeItem(PERSIST_CURRENT_LOCATION)
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: API.forgotPassword,
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: API.resetPassword,
        method: 'post',
        data,
    })
}

export async function apiMe() {
    return ApiService.fetchData({
        url: API.me,
        method: 'get',
    })
}
