import { PATH_ROUTING } from "./route.constant"

export const APP_NAME = 'XLM'
export const PERSIST_STORE_NAME = 'XLM/localstorage'
export const PERSIST_CURRENT_LOCATION = 'XLM/currentLocation'
export const PERSIST_LAST_TENANT_SELECTION = 'XLM/lastTenantSelection'
export const REDIRECT_URL_KEY = 'redirectUrl'
export const APP_THEME_GRAY = 'bg-gray-400'
export const LOGO_SRC_PATH = '/img/logo'
export const STEP = {
    LOGIN: 0,
    TENANT_SELECTOR: 1
}
export const SCREEN_LEVEL_STEP = {
    FARM_VIEW: 0,
    ROOM_VIEW: 1
}
export const ANIMAL_PAGE_CONSTANT = {
    ANIMAL_MANAGEMENT: 'animal-management',
    ALERTS: 'alerts',
    SICK_LIST: 'sick-list',
    WATCH_LIST: 'watch-list'
}
export const ANIMAL_PAGE_URL = {
    [ANIMAL_PAGE_CONSTANT.ANIMAL_MANAGEMENT]: PATH_ROUTING.animalManagement,
    [ANIMAL_PAGE_CONSTANT.ALERTS]: PATH_ROUTING.alerts,
    [ANIMAL_PAGE_CONSTANT.SICK_LIST]: PATH_ROUTING.alerts,
    [ANIMAL_PAGE_CONSTANT.WATCH_LIST]: PATH_ROUTING.alerts
} 
export const BACK_TO_ANIMAL_PAGE = {
    [ANIMAL_PAGE_CONSTANT.ANIMAL_MANAGEMENT]: 'container.AnimalDetail.backToAnimalManagement',
    [ANIMAL_PAGE_CONSTANT.ALERTS]: 'container.AnimalDetail.backToAlerts',
    [ANIMAL_PAGE_CONSTANT.SICK_LIST]: 'container.AnimalDetail.backToSickList',
    [ANIMAL_PAGE_CONSTANT.WATCH_LIST]: 'container.AnimalDetail.backToWatchlist'
} 
export const ANIMAL_MANAGEMENT_PAGE_TAB_VALUE = {
    ROOM_INVENTORY: 'room-inventory',
    ALERT_LIST: 'alert-list',
    SICK_LIST:'sick-list',
    WATCH_LIST: 'watch-list'
}
