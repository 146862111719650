import React from 'react'
import { useTranslation } from 'react-i18next'

const Total = (props) => {
    const { total } = props
    const { t } = useTranslation()
    return (
        <div className="pagination-total">
            {total > 1 ?
                t("global.txtTotalMultipleItems", { total: total }) :
                t("global.txtTotalSingleItem", { total: total })
            }
        </div>
    )
}

export default Total
