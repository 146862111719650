import { createSlice } from '@reduxjs/toolkit'

export const OVERVIEW_PAGE_TAB_VALUE = {
    INVENTORY: 'inventory',
    ANIMAL_STATUS:'animal-status',
    LOCATION_CONDITION: 'location-condition'
}

const initialState = {
    isOpenDialog: false,
    isOpenMoveBatchDialog: false,
    locationBatchConfirmation: false,
    selectedLocationBatch: {
        isStart: false,
        currentLocation: {},
        moveBatchValue: {}
    },
    isOpenRoomLayoutDialog: false,
    selectedItemInRoomLayout: {},
    isOpenAnimalHealthStatusDialog: false,
    selectedAnimalHealthStatus: {},
    isOpenAnimalBreedingStatusDialog: false,
    selectedAnimalBreedingStatus: {},
    currentTabContent: OVERVIEW_PAGE_TAB_VALUE.INVENTORY
}

const stateSlice = createSlice({
    name: 'overview/state',
    initialState,
    reducers: {
        resetOverviewState: () => initialState,
        setOpenDialog: (state, action) => {
            state.isOpenDialog = action.payload
        },
        setOpenMoveBatchDialog: (state, action) => {
            state.isOpenMoveBatchDialog = action.payload
        },
        toggleLocationBatchConfirmation: (state, action) => {
            state.locationBatchConfirmation = action.payload
        },
        setSelectedLocationBatch: (state, action) => {
            state.selectedLocationBatch = action.payload
        },
        setOpenRoomLayoutDialog: (state, action) => {
            state.isOpenRoomLayoutDialog = action.payload
        },
        setSelectedItemInRoomLayout: (state, action) => {
            state.selectedItemInRoomLayout = action.payload
        },
        setOpenAnimalHealthStatusDialog: (state, action) => {
            state.isOpenAnimalHealthStatusDialog = action.payload
        },
        setSelectedAnimalHealthStatus: (state, action) => {
            state.selectedAnimalHealthStatus = action.payload
        },
        setOpenAnimalBreedingStatusDialog: (state, action) => {
            state.isOpenAnimalBreedingStatusDialog = action.payload
        },
        setSelectedAnimalBreedingStatus: (state, action) => {
            state.selectedAnimalBreedingStatus = action.payload
        },
        setCurrentTabContent: (state, action) => {
            state.currentTabContent = action.payload
        },
    },
})

export const {
    resetOverviewState,
    setOpenDialog,
    setOpenMoveBatchDialog,
    toggleLocationBatchConfirmation,
    setSelectedLocationBatch,
    setOpenRoomLayoutDialog,
    setSelectedItemInRoomLayout,
    setOpenAnimalHealthStatusDialog,
    setSelectedAnimalHealthStatus,
    setOpenAnimalBreedingStatusDialog,
    setSelectedAnimalBreedingStatus,
    setCurrentTabContent
} = stateSlice.actions

export default stateSlice.reducer
