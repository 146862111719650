import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetFarms } from 'services/FarmService'
import { toastError } from 'utils/toast'

const prefix = `Xsights-XAHWM/farm`;

export const getFarmDetail = createAsyncThunk(
    `${prefix}/getFarmDetail`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiGetFarms()
            return response.data
        } catch (error) {
            toastError()
            return rejectWithValue(error);
        }

    }
)

const initialState = {
    farmDetail: {},
    loadingFarmDetail: false,
}

export const farmSlice = createSlice({
    name: 'farm',
    initialState,
    reducers: {
    },
    extraReducers: {
        [getFarmDetail.fulfilled]: (state, action) => {
            const { result } = action.payload
            if(result.length > 0) {
                state.farmDetail = result[0]
                state.loadingFarmDetail = false
            }
        },
        [getFarmDetail.pending]: (state) => {
            state.loadingFarmDetail = true
        },
        [getFarmDetail.rejected]: (state) => {
            state.farmDetail = {}
            state.loadingFarmDetail = false
        },
    }
})

export default farmSlice.reducer
