import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    cubeTokenTypeSnowflake: "",
    cubeTokenTypeBackend: "",
    cubeApiSnowflake: {},
    cubeApiBackend: {}
}

export const cubeSlice = createSlice({
    name: 'cube',
    initialState,
    reducers: {
        setCubeTokenTypeSnowflake: (state, action) => {
            state.cubeTokenTypeSnowflake = action.payload
        },
        setCubeTokenTypeBackend: (state, action) => {
            state.cubeTokenTypeBackend  = action.payload
        },
        setCubeApiSnowflake: (state, action) => {
            state.cubeApiSnowflake = action.payload
        },
        setCubeApiBackend: (state, action) => {
            state.cubeApiBackend  = action.payload
        },
    },

})

export const { 
    setCubeTokenTypeSnowflake,
    setCubeTokenTypeBackend,
    setCubeApiSnowflake,
    setCubeApiBackend
} = cubeSlice.actions

export default cubeSlice.reducer
