import { combineReducers } from '@reduxjs/toolkit'
import common from './commonSlice'
import tenantSetting from './tenantSettingSlice'


const reducer = combineReducers({
    common,
    tenantSetting
})

export default reducer
