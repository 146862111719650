import { createSlice } from '@reduxjs/toolkit'
import { PERSIST_LAST_TENANT_SELECTION, STEP } from 'constants/app.constant'



export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        signedIn: false,
        tenantIdentifier: '',
        step: STEP.LOGIN
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
            state.tenantIdentifier = ''
            state.step = STEP.LOGIN
        },
        onReselectTenant: (state) => {
            localStorage.removeItem(PERSIST_LAST_TENANT_SELECTION)
            state.signedIn = false
            state.tenantIdentifier = ''
            state.step = STEP.TENANT_SELECTOR
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setTenantIdentifier: (state, action) => {
            state.tenantIdentifier = action.payload
        },
        setLoginSteps: (state, action) => {
            state.step = action.payload
        },
    },
})

export const { 
    onSignInSuccess, 
    onSignOutSuccess, 
    onReselectTenant,
    setToken, 
    setTenantIdentifier,
    setLoginSteps
} =
    sessionSlice.actions

export default sessionSlice.reducer
