import { createSlice } from '@reduxjs/toolkit'
import { ANIMAL_PAGE_CONSTANT, SCREEN_LEVEL_STEP } from 'constants/app.constant'

export const initialState = {
    currentRouteKey: '',
    isOpenDialogSwitchTenant: false,
    previousPageBeforeAnimalDetails: ANIMAL_PAGE_CONSTANT.ANIMAL_MANAGEMENT,
    screenLevelStep: SCREEN_LEVEL_STEP.ROOM_VIEW
}

export const commonSlice = createSlice({
    name: 'base/common',
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setOpenDialogSwitchTenant: (state, action) => {
            state.isOpenDialogSwitchTenant = action.payload
        },
        setPreviousPageBeforeAnimalDetails: (state, action) => {
            state.previousPageBeforeAnimalDetails = action.payload
        },
        setScreenLevelStep: (state, action) => {
            state.screenLevelStep = action.payload
        },
    },
})

export const { 
    setCurrentRouteKey, 
    setOpenDialogSwitchTenant, 
    setPreviousPageBeforeAnimalDetails,
    setScreenLevelStep 
} = commonSlice.actions

export default commonSlice.reducer
