import React, { forwardRef, useMemo, useRef, useEffect, useState, useImperativeHandle } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Table, Pagination, Select, Checkbox, Alert } from 'components/ui'
import Loading from './Loading'
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
    getExpandedRowModel,
} from '@tanstack/react-table'
import NoDataScreen from 'components/shared/NoDataScreen'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const { Tr, Th, Td, THead, TBody, Sorter } = Table

const IndeterminateCheckbox = ((props) => {

    const { indeterminate, onChange, onCheckBoxChange, onIndeterminateCheckBoxChange, ...rest } = props

    const ref = useRef(null)

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, indeterminate])

    const handleChange = (e) => {
        onChange(e)
        onCheckBoxChange?.(e)
        onIndeterminateCheckBoxChange?.(e)
    }

    return <Checkbox className="mb-0" ref={ref} onChange={(_, e) => handleChange(e)} {...rest} />
})

const getCommonPinningStyles = (stickyColumns, column, isHeader=true) => {
    if(stickyColumns.length > 0) {
        let isPinned = stickyColumns.includes(column.id)
        let lastPinned = [...stickyColumns].pop() === column.id
        return {
            boxShadow: lastPinned ? '-4px 0 4px -4px gray inset' : undefined,
            left: isPinned ? `${column.getStart()}px` : undefined,
            position: isPinned ? 'sticky' : 'relative',
            maxWidth: isPinned && column.getSize(),
            minWidth: isPinned && column.getSize(),
            zIndex: isPinned ? 1 : 0,
            backgroundColor: isHeader ? '#f9fafb' : 'white',
            whiteSpace: 'nowrap',
          }
    }
  }

const DataTableStickyColumn = forwardRef((props, ref) => {
    const {
        columns: columnsProp,
        data,
        loading,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        onPaginationChange,
        onSelectChange,
        onSort,
        pageSizes,
        selectable,
        pagingData,
        isAlert = false,
        displayTotal = true,
        stickyColumns = [],
        isExpand = false,
        displaySelectLimit = true
    } = props

    const { limit, page, count } = pagingData

    const { t } = useTranslation()

    const [sorting, setSorting] = useState(null)
    const [expanded, setExpanded] = useState({})

    const pageSizeOption = useMemo(
        () =>
            pageSizes.map((number) => ({
                value: number,
                label: `${number} / ${t("global.txtPage")}`,
            })),
        // eslint-disable-next-line
        [pageSizes, t]
    )

    const handleCheckBoxChange = (checked, row) => {
        if (!loading) {
            onCheckBoxChange?.(checked, row)
        }
    }

    const handleIndeterminateCheckBoxChange = (checked, rows) => {
        if (!loading) {
            onIndeterminateCheckBoxChange?.(checked, rows)
        }
    }

    const handlePaginationChange = (page) => {
        if (!loading) {
            onPaginationChange?.(page)
        }
    }

    const handleSelectChange = (value) => {
        if (!loading) {
            onSelectChange?.(Number(value))
        }
    }

    useEffect(() => {
        if (Array.isArray(sorting)) {
            const sortOrder = sorting.length > 0 ? sorting[0].desc ? '-' : '' : ''
            const id = sorting.length > 0 ? sorting[0].id : ''
            if (!_.isEmpty(sortOrder) || !_.isEmpty(id)) {
                onSort?.(`${sortOrder}${id}`)
            }
            if(sorting.length === 0) {
                onSort?.(null)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting])

    const hasOldColumnMetaKey = columnsProp.some(col => col.Header || col.accessor || col.Cell)

    const finalColumns = useMemo(() => {

        const columns = columnsProp

        if (selectable) {
            return [
                {
                    id: 'select',
                    header: ({ table }) => (
                        <IndeterminateCheckbox
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={table.getIsSomeRowsSelected()}
                            onChange={table.getToggleAllRowsSelectedHandler()}
                            onIndeterminateCheckBoxChange={(e) => {
                                handleIndeterminateCheckBoxChange(
                                    e.target.checked,
                                    table.getRowModel().rows
                                )
                            }}
                        />
                    ),
                    cell: ({ row }) => (
                        <IndeterminateCheckbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            indeterminate={row.getIsSomeSelected()}
                            onChange={row.getToggleSelectedHandler()}
                            onCheckBoxChange={(e) =>
                                handleCheckBoxChange(
                                    e.target.checked,
                                    row.original
                                )
                            }
                        />
                    ),
                },
                ...columns
            ]
        }
        return columns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnsProp, selectable])

    const table = useReactTable({
        data,
        columns: hasOldColumnMetaKey ? [] : finalColumns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        manualPagination: true,
        manualSorting: true,
        onSortingChange: setSorting,
        onExpandedChange: setExpanded,
        getSubRows: (row) => row.subRows,
        getExpandedRowModel: getExpandedRowModel(),
        state: {
            sorting,
            expanded
        },
    })

    // eslint-disable-next-line
    const rows = useMemo(()=>{
        if(isExpand) {
            return table.getRowModel().rows
        } 
        return table.getRowModel().rows.slice(0, limit)
    })

    const resetSorting = () => {
        table.resetSorting()
    };

    const resetSelected = () => {
        table.toggleAllRowsSelected(false);
    }

    useImperativeHandle(ref, () => ({
        resetSorting,
        resetSelected,
        setSorting,
    }));

    if (hasOldColumnMetaKey) {

        const message = 'You are using old react-table v7 column config, please use v8 column config instead, refer to our demo or https://tanstack.com/table/v8'

        if (process.env.NODE_ENV === 'development') {
            console.warn(message)
        }

        return (
            <Alert>{message}</Alert>
        )
    }

    return (
        <Loading loading={loading} type="cover">
            <Table sticky={true}>
                <THead className='sticky z-10 top-0'>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                const { column } = header
                                return (
                                    <Th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        style={{
                                            width: header.getSize() !== 150 ? header.getSize() : undefined,
                                            whiteSpace: 'nowrap',
                                            ...getCommonPinningStyles(stickyColumns ,column)
                                        }}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <div
                                                className={
                                                    classNames(
                                                        header.column.getCanSort() && 'cursor-pointer select-none point',
                                                        loading && 'pointer-events-none'
                                                    )
                                                }
                                                onClick={() => {
                                                    if (header.column.getIsSorted() === 'asc') {
                                                        header.column.toggleSorting(true)
                                                    } else if (header.column.getIsSorted() === 'desc') {
                                                        header.column.clearSorting()
                                                    } else {
                                                        header.column.toggleSorting(false)
                                                    }
                                                }}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {header.column.getCanSort() && <Sorter sort={header.column.getIsSorted()} />}
                                            </div>
                                        )}
                                    </Th>
                                )
                            })}
                        </Tr>
                    ))}
                </THead>
                {loading && data.length === 0 ? (
                    <TBody className="w-full h-[400px]"></TBody>
                ) : !loading && data.length === 0 ? (
                    <TBody className="w-full"><Tr><Td colSpan={columnsProp.length}><NoDataScreen /></Td></Tr></TBody>
                ) : (
                    <TBody>
                        {rows.map((row) => {
                                let responded = true
                                if (isAlert) {
                                    responded = row.original.responded
                                }
                                return (
                                    <Tr key={row.id} className={!responded && "bg-red-100/50 hover:bg-red-200"}>
                                        {row.getVisibleCells().map((cell) => {
                                            const { column } = cell
                                            return (
                                                <Td key={cell.id}
                                                    style={{ ...getCommonPinningStyles(stickyColumns ,column, false) }}
                                                    >
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </Td>
                                            )
                                        })}
                                    </Tr>
                                )
                            })}
                    </TBody>
                )}
            </Table>
            <div className={classNames(
                "flex flex-wrap-reverse items-center mt-4",
                displaySelectLimit ? "justify-between" : "justify-end"
            )}>
                {displaySelectLimit && <div style={{ minWidth: 130 }}>
                    <Select
                        // className="mt-4"
                        size="sm"
                        menuPlacement="top"
                        isSearchable={false}
                        value={pageSizeOption.filter(
                            (option) => option.value === limit
                        )}
                        options={pageSizeOption}
                        onChange={(option) => handleSelectChange(option.value)}
                    />
                </div>}
                <Pagination
                    pageSize={limit}
                    currentPage={page}
                    total={count}
                    onChange={handlePaginationChange}
                    displayTotal={displayTotal}
                />
            </div>
        </Loading>
    )
})

DataTableStickyColumn.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    loading: PropTypes.bool,
    onCheckBoxChange: PropTypes.func,
    onIndeterminateCheckBoxChange: PropTypes.func,
    onPaginationChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    onSort: PropTypes.func,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    selectable: PropTypes.bool,
    skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
    skeletonAvatarProps: PropTypes.object,
    pagingData: PropTypes.shape({
        total: PropTypes.number,
        pageIndex: PropTypes.number,
        pageSize: PropTypes.number,
    }),
}

DataTableStickyColumn.defaultProps = {
    pageSizes: [10, 25, 50, 100],
    pagingData: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
    },
    data: [],
    columns: [],
    selectable: false,
    loading: false,
}

export default DataTableStickyColumn
