import React, { memo, useMemo, lazy, Suspense, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import { resetLocationManagementState } from 'views/LocationManagement/store/stateSlice'
import { resetPenManagementState } from 'views/PenManagement/store/stateSlice'
import { resetOverviewState } from 'views/Overview/store/stateSlice'
import { getCommonLocation, resetDataLocation } from 'store/location/locationSlice'
import { setTimezone } from 'store/base/tenantSettingSlice'
import { TIMEZONE } from 'constants/api.constant'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
    const dispatch = useDispatch()
    const layoutType = useSelector((state) => state.theme.layout.type)
    const { authenticated, getMe  } = useAuth()
    const tenants = useSelector(state => state.auth.user.tenants)
    const tenantIdentifier = useSelector(state => state.auth.session.tenantIdentifier)

    useDirection()

    useLocale()

    useEffect(()  =>  {
        if (authenticated) {
            async function fetchData() {
                await getMe()
            }
            fetchData();
            dispatch(getCommonLocation())

            if (!isEmpty(tenants) && tenantIdentifier) {
                const curTenant = tenants.find(
                    tenant => tenant.identifier === tenantIdentifier
                )
                if (curTenant) {
                    const { timezone } = curTenant.settings
                    if (timezone) {
                        dispatch(setTimezone(timezone))
                        moment.tz.setDefault(timezone)
                    } else {
                        dispatch(setTimezone(TIMEZONE))
                        moment.tz.setDefault(TIMEZONE)
                    }
                }
            }
        }
        // eslint-disable-next-line 
    }, [authenticated])

    useEffect(() => {
        if (!authenticated) {
            dispatch(resetLocationManagementState())
            dispatch(resetPenManagementState())
            dispatch(resetOverviewState())
            dispatch(resetDataLocation())
        }
        // eslint-disable-next-line 
    }, [authenticated])

    const AppLayout = useMemo(() => {
        if (authenticated) {
            return layouts[layoutType]
        }
        return lazy(() => import('./AuthLayout'))
        // eslint-disable-next-line 
    }, [layoutType, authenticated])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    )
}

export default memo(Layout)
