import ApiService from './ApiService'
import API from 'configs/apis'

export async function apiGetFarms() {
    const result = await ApiService.fetchData({
        url: API.getFarms,
        method: 'get',
    })
    return result
}

export async function apiGetTagsStats() {
    const result = await ApiService.fetchData({
        url: API.getTagsStats,
        method: 'get',
    })
    return result
}