import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    deleteConfirmation: false,
    selectedLocation: '',
    isOpenDialog: false,
    isOpenLocationStartNewDialog: false,
}

const stateSlice = createSlice({
    name: 'location/state',
    initialState,
    reducers: {
        resetLocationManagementState: () => initialState,
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload
        },
        setOpenDialog: (state, action) => {
            state.isOpenDialog = action.payload
        },
        setOpenLocationStartNewDialog: (state, action) => {
            state.isOpenLocationStartNewDialog = action.payload
        },
    },
})

export const {
    resetLocationManagementState,
    toggleDeleteConfirmation,
    setSelectedLocation,
    setOpenDialog,
    setOpenLocationStartNewDialog
} = stateSlice.actions

export default stateSlice.reducer
