import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY, REQUEST_HEADER_IDENTIFIER, STATUS_OF_RESOURCE } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onReselectTenant, onSignOutSuccess } from '../store/auth/sessionSlice'
import { toastError } from 'utils/toast'
import { apiMe } from './AuthService'
import { setUser } from 'store/auth/userSlice'
import { getCommonLocation } from 'store/location/locationSlice'

const unauthorizedCode = [401]
let unauthorizedToastShown = false; // Flag to track if the unauthorized toast has been shown

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
})

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        let accessToken = persistData.auth.session.token
        let tenantIdentifier = persistData.auth.session.tenantIdentifier

        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.token
        }

        if (accessToken) {
            config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
            config.headers[REQUEST_HEADER_IDENTIFIER] = tenantIdentifier
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => {
        // Reset the flag on successful responses
        unauthorizedToastShown = false; 
        return response;
    },
    async (error) => {
        const { response } = error
        if (response && unauthorizedCode.includes(response.status)) {
            // Show toast only if it hasn't been shown yet
            if (!unauthorizedToastShown) {
                toastError({ keyTrans: "global.unauthorized" })
                unauthorizedToastShown = true; // Set the flag to true
            }
            store.dispatch(onSignOutSuccess())
            return response
        }

        if (response && response.data && response.data.detail === "INVALID_MEMBERSHIP") {
            toastError({ keyTrans: "global.invalid_membership" })
            store.dispatch(onSignOutSuccess())
            return {}
        }
        if (response && response.data && response.data.detail === "INACTIVE_TENANT") {
            toastError({ keyTrans: "global.inactiveTenant" })
            const me = await apiMe()
            if (me.data) {
                me.data.tenants = me.data.tenants.filter(
                    tenant => tenant.status !== STATUS_OF_RESOURCE.INACTIVE
                )
                const { tenants } = me.data
                if (tenants.length === 0) {
                    store.dispatch(onSignOutSuccess())
                    return {}
                }
                store.dispatch(setUser(me.data))
            }
            store.dispatch(onReselectTenant())
            return {}
        }
        if (response && response.data && 
            (response.data.detail === "INACTIVE_FARM" || response.data.detail === "FARM_NOT_FOUND")) {
            toastError({ keyTrans: "global.inactiveFarm" })
            const me = await apiMe()
            if (me.data) {
                me.data.tenants = me.data.tenants.filter(
                    tenant => tenant.status !== STATUS_OF_RESOURCE.INACTIVE
                )
                const { tenants } = me.data
                if (tenants.length === 0) {
                    store.dispatch(onSignOutSuccess())
                    return {}
                }
                store.dispatch(setUser(me.data))
            }
            store.dispatch(onReselectTenant())
            return {}
        }
        if (response && response.data && response.data.detail === "INACTIVE_LOCATION") {
            toastError({ keyTrans: "global.inactiveLocation" })
            store.dispatch(getCommonLocation())
            return response
        }
        return Promise.reject(error)
    }
)

export default BaseService