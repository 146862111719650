import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiMe, apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess, setToken, setLoginSteps, setTenantIdentifier } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { PERSIST_LAST_TENANT_SELECTION, REDIRECT_URL_KEY, STEP } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { toastError } from 'utils/toast'
import { STATUS_OF_RESOURCE } from 'constants/api.constant'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn, tenantIdentifier } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                const { access_token: token } = resp.data
                dispatch(setToken(token))
                const me = await apiMe()
                if (me.data) {
                    me.data.tenants = me.data.tenants.filter(
                        tenant => tenant.status !== STATUS_OF_RESOURCE.INACTIVE
                    );
                    const { tenants } = me.data
                    if(tenants.length === 0) {
                        dispatch(onSignOutSuccess())
                        toastError({ keyTrans: "global.invalid_membership" })
                        return {
                            status: 'failed',
                            message: '',
                        }
                    }
                    dispatch(setUser(me.data))
                    if (tenants.length === 1) {
                        dispatch(setTenantIdentifier(tenants[0].identifier))
                        setLastTenantSelection(tenants[0].identifier)
                        setTimeout(() => {
                            dispatch(onSignInSuccess())
                        }, 100);
                    } else {
                        const lastTenantSelection = getLastTenantSelection()
                        if(lastTenantSelection) {
                            dispatch(setTenantIdentifier(lastTenantSelection))
                        }
                        setTimeout(() => {
                            dispatch(setLoginSteps(STEP.TENANT_SELECTOR))
                        }, 100)
                    }
                }
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            let message = "global.somethingWrong"
            if (errors?.response?.data?.detail === "LOGIN_BAD_CREDENTIALS") {
                message = "container.PageSignIn.loginBadCredentials"
            }
            return {
                status: 'failed',
                message: message,
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(setLoginSteps(STEP.LOGIN))
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    const setLastTenantSelection = (val) => {
        localStorage.setItem(PERSIST_LAST_TENANT_SELECTION, val)
    }

    const getLastTenantSelection = () => {
        return localStorage.getItem(PERSIST_LAST_TENANT_SELECTION)
    }
    
    const getMe = async () => {
        const me = await apiMe() 
        if(me.data) {
            me.data.tenants = me.data.tenants.filter(
                tenant => tenant.status !== STATUS_OF_RESOURCE.INACTIVE
            );
            dispatch(setUser(me.data))
        }
    }

    return {
        authenticated: token && tenantIdentifier && signedIn,
        signIn,
        signUp,
        signOut,
        setLastTenantSelection,
        getLastTenantSelection,
        getMe
    }
}

export default useAuth
