import { PATH_ROUTING } from "constants/route.constant"

const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: PATH_ROUTING.dashboard,
    unAuthenticatedEntryPath: PATH_ROUTING.signIn,
    tourPath: '/',
    locale: 'en',
    enableMock: true,
}

export default appConfig
