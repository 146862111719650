import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import commonLocation from './location/locationSlice'
import cube from './cube/cubeSlice'
import farm from './farm/farmSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        commonLocation,
        cube,
        farm,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
