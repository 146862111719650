import React from 'react';
import {
    Notification,
    toast,
} from 'components/ui'

export const toastCreateSuccess = ({ keyTrans = 'global.toastMsgAddSuccess', msg = { name: '' }, } = {}) =>
    toast.push(
        <Notification
            title={keyTrans}
            type={'success'}
        />,
        { placement: 'top-end' }
    )

export const toastUpdateSuccess = ({ keyTrans = 'global.toastMsgUpdateSuccess', msg = { name: '' }, } = {}) =>
    toast.push(
        <Notification
            title={keyTrans}
            type={'success'}
        />,
        { placement: 'top-end' }
    )

export const toastDeleteSuccess = ({ keyTrans = 'global.toastMsgRemoveSuccess', msg = { name: '' }, } = {}) =>
    toast.push(
        <Notification
            title={keyTrans}
            type={'success'}
        />,
        { placement: 'top-end' }
    )

export const toastError = ({ keyTrans = 'global.toastMsgError', name } = {}) =>
    toast.push(
        <Notification
            title={keyTrans}
            type={'danger'}
        />,
        { placement: 'top-end' }
    )

export const callToast = ({ keyTrans, type }) =>
    toast.push(
        <Notification
            title={keyTrans}
            type={type}
        />,
        { placement: 'top-end' }
    )