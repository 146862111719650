export const ROOT = '/'
export const APP_PREFIX_PATH = '/app'
export const AUTH_PREFIX_PATH = '/auth'
export const UI_COMPONENTS_PREFIX_PATH = '/ui-components'
export const PAGES_PREFIX_PATH = '/pages'
export const DOCS_PREFIX_PATH = '/docs'
export const PATH_IDS = {
    home: 'home',
    resetPassword: 'resetPassword',
    forgotPassword: 'forgotPassword',
    signIn: 'signIn',
    manage: 'manage',
    locationManagement: 'locationManagement',
    penManagement: 'penManagement',
    overview: 'overview',
    animalManagement: 'animalManagement',
    alerts: 'alerts',
    report: 'report',
    dashboard: 'dashboard',
}
export const PATH_ROUTING = {
    home: '/home',
    resetPassword: '/reset-password',
    forgotPassword: '/forgot-password',
    signIn: '/sign-in',
    manage: '',
    locationManagement: '/location',
    locationDetail: '/location/:id',
    penManagement: '/pen',
    overview: '/overview',
    animalManagement: '/animal',
    animalDetail: '/animal/:tagId',
    alerts: '/alerts',
    report: '/report',
    dashboard: '/dashboard',
}
