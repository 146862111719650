import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { INITIAL_FILTER_TABLE_DATA, MAX_FETCH_LIMIT, STATUS_OF_RESOURCE } from 'constants/api.constant';
import { isArray } from 'lodash';
import { apiGetLocationForm, apiGetLocations } from 'services/LocationService';
import { onReselectTenant } from 'store/auth/sessionSlice';
import { toastError } from 'utils/toast';

const prefix = `Xsights-XAHWM/common-location`;

export const getCommonLocation = createAsyncThunk(
    `${prefix}/getCommonLocation`,
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiGetLocations({ limit: MAX_FETCH_LIMIT })
            if (response.data && response.data.result && isArray(response.data.result)) {
                const listLocation = response.data.result.filter(item =>
                    item.status !== STATUS_OF_RESOURCE.INACTIVE
                )
                if (listLocation.length === 0) {
                    toastError({ keyTrans: "container.LocationManagement.location_not_found" })
                    dispatch(onReselectTenant())
                }
            }
            return response.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const getLocationDetail = createAsyncThunk(
    `${prefix}/getLocationDetail`,
    async (id, { rejectWithValue }) => {
        try {
            const response = await apiGetLocationForm(id)
            return response.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

const initialState = {
    data: [],
    loading: false,
    currentLocation: {},
    currentBatch: {},
    locationSetting: {},
    locationDetail: {},
    loadingLocationDetail: false,
    activeRoomType: null,
    activeRoom: null,
    isOpenFarmNavigationChartDialog: false,
    selectedFarmNavigationChartDialog: {},
    isOpenFarmNavigationChartTableDialog: false,
    selectedFarmNavigationChartTableDialog: {},
    tableDataFarmNavigationChart: {
        ...INITIAL_FILTER_TABLE_DATA
    }
}

export const locationSlice = createSlice({
    name: 'commonLocation',
    initialState,
    reducers: {
        setCurrentLocation: (state, action) => {
            state.currentLocation = action.payload
        },
        setLoadingLocationDetail: (state, action) => {
            state.loadingLocationDetail = action.payload
        },
        updateDataLocation: (state, action) => {
            state.data = action.payload
        },
        setActiveRoom: (state, action) => {
            const { activeRoomType, activeRoom } = action.payload
            state.activeRoom = activeRoom
            state.activeRoomType = activeRoomType
        },
        setOpenFarmNavigationChartDialog: (state, action) => {
            state.isOpenFarmNavigationChartDialog = action.payload
        },
        setSelectedFarmNavigationChartDialog: (state, action) => {
            state.selectedFarmNavigationChartDialog = action.payload
        },
        setOpenFarmNavigationChartTableDialog: (state, action) => {
            state.isOpenFarmNavigationChartTableDialog = action.payload
        },
        setSelectedFarmNavigationChartTableDialog: (state, action) => {
            state.selectedFarmNavigationChartTableDialog = action.payload
        },
        setTableDataFarmNavigationChart: (state, action) => {
            state.tableDataFarmNavigationChart = action.payload
        },
        resetDataLocation: () => initialState
    },
    extraReducers: {
        [getCommonLocation.fulfilled]: (state, action) => {
            // let currentLocation = {}
            const data = action.payload
            if (data && data.result) {
                const locationList = data.result.filter(item =>
                    item.status !== STATUS_OF_RESOURCE.INACTIVE
                )
                state.data = locationList
                // let currentLocationId = localStorage.getItem(PERSIST_CURRENT_LOCATION)
                // if (currentLocationId) {
                //     let result = locationList.find(item => item.id === parseInt(currentLocationId))
                //     if (result) {
                //         currentLocation = result
                //     } else {
                //         currentLocation = locationList[0] ? locationList[0] : {}
                //     }
                // } else {
                //     currentLocation = locationList[0] ? locationList[0] : {}
                // }
            } 
            // else {
            //     currentLocation = {}
            // }
            // state.currentLocation = currentLocation
            // if (isEmpty(currentLocation)) {
            //     state.currentBatch = {}
            //     state.locationSetting = {}
            //     state.locationDetail = {}
            // }
            state.loading = false
        },
        [getCommonLocation.pending]: (state) => {
            state.loading = true
        },
        [getLocationDetail.fulfilled]: (state, action) => {
            let result = action.payload
            if (result) {
                const { current_batch, settings } = result
                state.currentBatch = current_batch
                state.locationSetting = settings
                state.currentLocation = result
            }
            state.loadingLocationDetail = false
        },
        [getLocationDetail.pending]: (state, action) => {
            state.currentBatch = {}
            state.locationSetting = {}
            state.currentLocation = {}
            state.loadingLocationDetail = true
        },
        [getLocationDetail.rejected]: (state, action) => {
            state.currentLocation = {}
            state.loadingLocationDetail = false
        },
    }
})

export const {
    resetDataLocation,
    setCurrentLocation,
    updateDataLocation,
    setLoadingLocationDetail,
    setActiveRoom,
    setOpenFarmNavigationChartDialog,
    setSelectedFarmNavigationChartDialog,
    setOpenFarmNavigationChartTableDialog,
    setSelectedFarmNavigationChartTableDialog,
    setTableDataFarmNavigationChart
} = locationSlice.actions

export default locationSlice.reducer
