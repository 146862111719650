import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    deleteConfirmation: false,
    selectedPen: '',
    isOpenDialog: false,
    isOpenDialogViewDetails: false,
}

const stateSlice = createSlice({
    name: 'location/state',
    initialState,
    reducers: {
        resetPenManagementState: () => initialState,
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedPen: (state, action) => {
            state.selectedPen = action.payload
        },
        setOpenDialog: (state, action) => {
            state.isOpenDialog = action.payload
        },
        setOpenDialogViewDetails: (state, action) => {
            state.isOpenDialogViewDetails = action.payload
        }
    },
})

export const {
    resetPenManagementState,
    toggleDeleteConfirmation,
    setSelectedPen,
    setOpenDialog,
    setOpenDialogViewDetails
} = stateSlice.actions

export default stateSlice.reducer
