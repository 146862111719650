import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './lang/en.json'
import vi from './lang/vi.json'
import th from './lang/th.json'
import appConfig from 'configs/app.config'

const resources = {
    en: {
        translation: en,
    },
    vi: {
        translation: vi,
    },
    th: {
        translation: th,
    },
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: appConfig.locale,
    lng: appConfig.locale,
    interpolation: {
        escapeValue: false,
    },
})

export const dateLocales = {
    en: () => import('dayjs/locale/en'),
    vi: () => import('dayjs/locale/vi'),
    th: () => import('dayjs/locale/th'),
}

export const momentLocales = {
    en: () => import('moment'),
    vi: () => import('moment/locale/vi'),
    th: () => import('moment/locale/th'),
}


export const apexChartLocales = {
    en: {
        defaultLocale: 'en',
        toolbar: {
            show: false,
        }
    },
    vi: {
        locales: [{
            name: 'vi',
            options: {
                months: [
                    'Tháng Một', 'Tháng Hai', 'Tháng Ba', 'Tháng Tư', 'Tháng Năm', 'Tháng Sáu', 'Tháng Bảy', 'Tháng Tám', 'Tháng Chín', 'Tháng Mười', 'Tháng Mười Một', 'Tháng Mười Hai'
                ],
                shortMonths: [
                    'Thg 01', 'Thg 02', 'Thg 03', 'Thg 04', 'Thg 05', 'Thg 06', 'Thg 07', 'Thg 08', 'Thg 09', 'Thg 10', 'Thg 11', 'Thg 12'
                ],
                days: ['Chủ Nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'],
                shortDays: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
            }
        }],
        defaultLocale: 'vi',
        toolbar: {
            show: false,
        }
    },
    th: {
        locales: [{
            name: 'th',
            options: {
                months: [
                    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
                ],
                shortMonths: [
                    'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
                ],
                days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
                shortDays: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
            }
        }],
        defaultLocale: 'th',
        toolbar: {
            show: false,
        }
    }
};

export default i18n
